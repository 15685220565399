import React from "react";
import { setInterval } from "timers";

export interface ServerControllerProps {}

const ServerController: React.FC<ServerControllerProps> = (props) => {
  const [serverStatus, setServerStatus] = React.useState("unknown");
  const [ipAddress, setIpAddress] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const updateServerStatus = () => {
    getServerStatus()
      .then((response) => {
        console.log(response);
        const payload = response.payload;
        const stateName = payload.state_name;
        setServerStatus(stateName);
        setIpAddress(payload.public_ip);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching server status: ", err);
        console.log(err);
        setServerStatus("error");
      });
  };

  React.useEffect(() => {
    updateServerStatus();

    const interval = setInterval(() => {
      updateServerStatus();
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const onPressStartServer = () => {
    setIsLoading(true);
    setServerStatus("updating");
    startServer()
      .then((response) => {
        console.log(response);
        updateServerStatus();
      })
      .catch((err) => {
        console.log("Error starting server: ", err);
        console.log(err);
        setServerStatus("error");
      });
  };

  const onPressStopServer = () => {
    setIsLoading(true);
    setServerStatus("updating");
    setIpAddress(null);

    stopServer()
      .then((response) => {
        console.log(response);
        updateServerStatus();
      })
      .catch((err) => {
        console.log("Error stopping server: ", err);
        console.log(err);
        setServerStatus("error");
      });
  };

  const serverIsRunning = serverStatus === "running";
  let buttonElement = null;
  if (serverIsRunning) {
    buttonElement = serverButton(onPressStopServer, "Stop Server", isLoading);
  } else {
    const isDisabled = isLoading || serverStatus !== "stopped";
    buttonElement = serverButton(
      onPressStartServer,
      "Start Server",
      isDisabled
    );
  }

  let serverBgColor = "bg-yellow-200";
  let serverTextColor = "text-yellow-800";
  if (serverStatus === "running") {
    serverBgColor = "bg-green-200";
    serverTextColor = "text-green-800";
  } else if (serverStatus === "stopped") {
    serverBgColor = "bg-red-200";
    serverTextColor = "text-red-800";
  }

  const serverStatusBox = (
    <div
      className={
        serverBgColor +
        " " +
        serverTextColor +
        " p-2 rounded-sm flex justify-between mb-2 text-lg"
      }
    >
      <div className="text-md my-auto">Server Status</div>
      <div className="font-bold">{serverStatus.toUpperCase()}</div>
    </div>
  );

  const displayIdAddress =
    ipAddress && serverStatus === "running" ? ipAddress : "N/A";
  const serverIpBox = (
    <div className="bg-gray-100 p-2 rounded-sm flex justify-between mb-2 text-lg">
      <div className="text-md my-auto">IP Address</div>
      <div className="font-bold">{displayIdAddress}</div>
    </div>
  );

  return (
    <div className="max-w-md border mx-auto p-4 mt-4 bg-white rounded-md">
      <h1 className="text-2xl font-bold text-center mb-4">Valheim Server</h1>
      {serverStatusBox}
      {serverIpBox}
      <div className="flex">{buttonElement}</div>
    </div>
  );
};

const serverButton = (
  serverButtonFunction: any,
  label: string,
  isLoading: boolean
) => {
  return (
    <button
      className="w-1/2 mt-4 bg-blue-500 hover:bg-blue-700 disabled:opacity-80 text-center p-2 text-white 
      text-lg
      mx-auto rounded-full
      disabled:cursor-default
      disabled:bg-gray-300
      "
      onClick={serverButtonFunction}
      disabled={isLoading}
    >
      {label}
    </button>
  );
};

const getServerStatus = () => {
  return fetch("https://api.valheim.pixegami.com/get_server_status", {
    method: "GET",
  }).then((data) => data.json());
};

const startServer = () => {
  return fetch("https://api.valheim.pixegami.com/start_server", {
    method: "GET",
  }).then((data) => data.json());
};

const stopServer = () => {
  return fetch("https://api.valheim.pixegami.com/stop_server", {
    method: "GET",
  }).then((data) => data.json());
};

export default ServerController;
