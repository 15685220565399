import React from "react";
import ServerController from "./components/ServerController";

function App() {
  return (
    <div>
      <ServerController />
    </div>
  );
}

export default App;
